@import "~bootstrap/dist/css/bootstrap.css";

/* You can add global styles to this file, and also import other style files */

html {
  font-family: 'Comfortaa', cursive;
  padding: 0;
  margin: 0;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: inset 0px 21px 25px 0px rgb(255 255 255 / 30%);

  height: -webkit-fill-available;
}


html, body {
  height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  // background-image: linear-gradient(to bottom left, #da8ac5, #99d1f775);

  text-align: center;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to bottom left, #da8ac5, #99d1f775);
  background-repeat: no-repeat;
  z-index: -1;
}

ol{
  margin-top: 0;
  padding-left: 20px;
  margin-left: 15px;
  margin-right: 15px;
}


::selection {
  color: #6e3b98;
  background: #ffb7b7;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  color: #6e3b98;
  background: #ffb7b7;
  /* Gecko Browsers */
}

.page-content{
  margin-left: 10px;
  margin-right: 10px;
}

hr {
  overflow: visible;
  /* For IE */
  height: 30px;
  border-style: solid;
  border-color: black;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}

hr:before {
  /* Not really supposed to work, but does */
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: black;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}

h1 {
  font-size: 30px;
  margin-top: 35px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  margin-bottom: 3px;
}

.lofi-header {
  font-family: 'Comfortaa', cursive;

  margin-top: 12px;
  width: 93%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
}

.panel {
  padding-top: 20px;
  padding-bottom: 5px;
  width: 100%;
  text-indent: 0.01px;
  transition: .2s linear;
  flex-wrap: wrap;
  text-align: center;
}

.inner-panel {
  max-width: 875px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

#intro-panel {
  margin-top: 35px;
}

// .carousel-indicators [data-bs-target] {
//   width: 35px;
//   height: 10px;
//   padding: 1px;
//   margin-right: 5px;
//   margin-left: 5px;
// }

// .carousel-indicators{
//   bottom: 45px;
// }


main {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 30px;
}

section {
  width: 100%;
}

figure {
  margin-left: 15px;
  margin-right: 15px;
}

.images {
  width: 40px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  transition: .2s linear;
  border-radius: 25px;
}

.images:hover {
  transform: translateY(-2px);
  box-shadow: 1px 2px black;
}

.youtube-widget {
  width: 90%;
  height: 50vw;
  max-height: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 8px;
  border-radius: 10px;
}

.member-photo {
  min-width: 100px;
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 130px;
  border: 2px solid black;
}

.member-photo:hover {
  transform: translateY(-4px);
  box-shadow: 2px 2px black;
}

#main-content {
  margin-bottom: 20px;
}

.team-members {
  display: inline-flex;
}

.member-name {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
}

.notice {
  margin-top: 10px;
  line-height: 24px;
  font-size: 18px;
  max-width: 89%;
  margin-left: auto;
  margin-right: auto;
}

.page-container {
  margin-top: 115px;
}

@media screen and (max-width:800px) {

  body .community {
      margin-left: 5%;
      margin-right: 5%;
  }

}

@media screen and (max-width:750px) {
  .page-container {
    margin-top: 20px !important;
  }

  .after-carousel {
    margin-top: calc(100vh - 80px) !important;
  }

  #youtube-widget {
    margin-bottom: 0.4vw;
  }

  body .notice {
    line-height: 21px;
  }

  body h1 {
      font-size: 25px;
  }

  body .team-members {
      display: unset;
  }

  body figure {
      display: inline-flex;
      margin-left: 8%;
      margin-right: 4%;
      margin-top: 3px;
      margin-bottom: 3px;
      min-width: 165px;
      font-size: 14px;
  }

  body figcaption {
    text-align: left;
    margin-left: 37px;
    margin-top: 15px;
    margin-right: 0%;
    max-width: 87px;
    min-width: 87px;
  }

  body .member-photo {
      margin-left: -30px;
      margin-right: -30px;
  }

  body .notice {
      font-size: 16px;
  }

  body .fb-like {
      margin-left: 0;
  }
}

/* The special Button */

.special-button {
  width: 160px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.special-button:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.special-button:focus {
  outline: none;
}


